export const TenkenKanrikunLocalOrigin = 'localhost'
export const TenkenKanrikunStgOrigin = 'stg.tenken-kanrikun.itandibb.com'
export const TenkenKanrikunPrdOrigin = 'tenken-kanrikun.itandibb.com'

export const resolveEnv = () => {
  if (typeof window === 'undefined') {
    return ''
  }

  switch (window.location.hostname) {
    case TenkenKanrikunLocalOrigin:
      return 'development'
    case TenkenKanrikunStgOrigin:
      return 'staging'
    case TenkenKanrikunPrdOrigin:
      return 'production'
    default:
      throw new Error('Invalid Origin')
  }
}
