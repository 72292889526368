import React from 'react'
import { Grid, Box } from '@mui/material'
import { Header } from '@component//Header'
import { useItandiAccountSessions } from '@hooks/useItandiAccountSessions'
import { useItandiAccountUserSessions } from '@hooks/useItandiAccountUserSessions'

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const heights = {
    header: '45px',
    footer: '30px',
  }
  const { data: account } = useItandiAccountSessions()
  const { data: itandiUserSession } = useItandiAccountUserSessions()
  if (account && !itandiUserSession) {
    return null
  }
  if (account && !itandiUserSession?.user) {
    return (
      <span>
        点検管理くんの利用準備が完了していません。ユーザーの設定をしてください。
      </span>
    )
  }
  return (
    <>
      {account && itandiUserSession && (
        <Header account={account} user={itandiUserSession} />
      )}
      <Box
        component='main'
        sx={{
          backgroundColor: 'grey.100',
          width: '100%',
          // - header分 - footer分
          minHeight: `calc(100vh - ${heights.header} - ${heights.footer})`,
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <Grid container>{children}</Grid>
      </Box>
    </>
  )
}
