/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'react-toastify/dist/ReactToastify.css'
import { Loader } from '@itandi/itandi-bb-ui'
import { ToastContainer } from 'react-toastify'
import { TkHead } from '@component/TkHead'
import { z } from 'zod'
import { zodCustomErrorMap } from '@hooks/zodCustomErrorMap'
import { Layout } from '../components/Layout'
import 'dayjs/locale/ja'
import 'remixicon/fonts/remixicon.css'

export const theme = createTheme()
const queryClient = new QueryClient()

z.setErrorMap(zodCustomErrorMap)

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  const [display, setDisplay] = useState(false)
  useEffect(() => {
    if (router.isReady) {
      setDisplay(true)
    }
  }, [router.isReady])

  if (!display) {
    return <Loader />
  }

  return (
    <>
      <TkHead description='' title='点検管理くん' />
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position='top-right'
        rtl={false}
        theme='light'
      />
      <LocalizationProvider adapterLocale='ja' dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ThemeProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </>
  )
}
/* eslint-disable-next-line import/no-default-export */
export default App
